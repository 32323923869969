import React, { Fragment } from 'react';

import Link from '../components/link/link';

import CIHR from './assets/cihr.svg';
import ComputeCanada from './assets/compute-canada.png';
import ComputeCanada2x from './assets/compute-canada2x.png';
import ComputeCanada3x from './assets/compute-canada3x.png';
import GenomeCanada from './assets/genome-canada.svg';
import LTRI from './assets/ltri.svg';
import OntarioGenomics from './assets/ontario-genomics.svg';

const AboutFunding = () => (
  <Fragment>
    <p>
      Funding for this project was provided by:
    </p>
    <div className="about__funding">
      <Link
        outline
        to="http://www.cihr-irsc.gc.ca"
      >
        <img
          alt="CIHR"
          height={43}
          src={CIHR}
        />
      </Link>
      <Link
        outline
        to="https://www.computecanada.ca"
      >
        <img
          alt="Compute Canada"
          height={47}
          src={ComputeCanada}
          srcSet={`
            ${ComputeCanada} 1x,
            ${ComputeCanada2x} 2x,
            ${ComputeCanada3x} 3x
          `}
        />
      </Link>
      <Link
        outline
        to="https://www.genomecanada.ca"
      >
        <img
          alt="Genome Canada"
          height={40}
          src={GenomeCanada}
        />
      </Link>
      <Link
        outline
        to="http://www.ontariogenomics.ca"
      >
        <img
          alt="Ontario Genomics"
          height={40}
          src={OntarioGenomics}
        />
      </Link>
      <Link
        outline
        to="http://www.lunenfeld.ca"
      >
        <img
          alt="LTRI"
          height={40}
          src={LTRI}
        />
      </Link>
    </div>
  </Fragment>
);

export default AboutFunding;
