import PropTypes from 'prop-types';
import React, { lazy, Suspense, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import About from '../about/about';
import Home from '../home/home-container';
import Loading from './loading';
import NotFoundPage from './not-found';
import PrivateRoute from './private-route';

const Analyze = lazy(() => import('../analyze/analyze-container'));
const Downloads = lazy(() => import('../downloads/downloads'));
const Explore = lazy(() => import('../explore/explore-container'));
const Help = lazy(() => import('../help/help-container'));
const User = lazy(() => import('../user/user-container'));

const Routing = ({
  children,
}) => {
  useLayoutEffect(() => window.scrollTo(0, 0));

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        {children}
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/analyze" component={Analyze} />
          <PrivateRoute path="/about" component={About} />
          <PrivateRoute path="/explore" component={Explore} />
          <PrivateRoute path="/downloads" component={Downloads} />
          <PrivateRoute path="/help" component={Help} />
          <Route path="/user" component={User} />
          <Route path="*" exact component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Router>
  );
};

Routing.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Routing;
