import { createAsyncThunk } from '@reduxjs/toolkit';

import fetch from '../../utils/fetch';

const fetchReport = createAsyncThunk(
  'reports/fetchReportStatus',
  async (options, { rejectWithValue }) => {
    const { id, reportType } = options;
    if (id && reportType) {
      const route = `/report?id=${id}&type=${reportType}`;
      const response = await fetch(route);

      if (response.error) {
        return rejectWithValue('retrieval failed');
      }
      return response.data;
    }
    return {};
  },
);

export default fetchReport;
