import styled from 'styled-components';

const Div = styled.div`
  margin-right: 8px;
  position: relative;
  z-index: 10;

  & button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  & button:focus {
    outline: 0;
  }
  & button:focus *,
  & button:hover * {
    fill: ${props => props.theme.accentPrimary};
  }

  & .nav__menu {
    height: 20px;
    width: 25px;
  }
  & .nav__menu > * {
    transition: fill 0.3s ease;
    fill: ${props => props.theme.fontLight};
  }

  & ul {
    background-color: ${props => props.theme.backgroundLight};
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
    font-family: ${props => props.theme.fontStackPrimary};
    height: 100vh;
    margin: 0;
    list-style: none;
    padding: 10px;
    position: fixed;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 100% 0%;
    transition-duration: 0.2s;
    transition-property: transform;
    transition-timing-function: ${props => props.theme.timingFunction};
  }

  & ul.nav__menu_open {
    transform: scaleX(1);
  }

  & ul.nav__menu_closed {
    transform: scaleX(0);
  }

  & li {
    padding: 0.3rem;
    text-transform: uppercase;
  }

  & a,
  & a:link,
  & a:active,
  & a:focus,
  & a:hover {
    color: ${props => props.theme.colorPrimary2};
    display: block;
    text-decoration: none;
    width: 100%;
  }
  & a:after {
    background: ${props => props.theme.colorPrimary2};
    content: '';
    display: block;
    height: 1px;
    transition-duration: 0.3s;
    transition-property: width;
    transition-timing-function: ${props => props.theme.timingFunction};
    width: 0px;
  }
  & a:focus {
    outline: none;
  }
  & a:focus:after,
  & a:hover:after,
  & a:active:after {
    width: 100%;
  }
`;

export default Div;
