import analysis from './analysis/analysis-reducer';
import cookies from './cookies/cookie-reducer';
import reports from './reports/reports-reducer';
import search from './search/search-reducer';
import user from './user/user-reducer';

const reducers = {
  analysis,
  cookies,
  reports,
  search,
  user,
};

export default reducers;
