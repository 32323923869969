import { getLocalStorage } from '../../components/local-storage/local-storage';
import { saveActionKey } from './save-actions';

const reHydrateStore = () => (
  Object.values(saveActionKey).reduce((accum, key) => {
    const value = getLocalStorage(key);
    return {
      ...accum,
      [key]: value ? JSON.parse(value) : undefined,
    };
  }, {})
);

export default reHydrateStore;
