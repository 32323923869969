import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngry,
  faFrown,
  faMeh,
  faSmile,
  faSmileWink,
} from '@fortawesome/pro-solid-svg-icons';


const strength = (score) => {
  const options = {
    0: { icon: faAngry, message: 'terrible' },
    1: { icon: faFrown, message: 'bad' },
    2: { icon: faMeh, message: 'weak' },
    3: { icon: faSmile, message: 'good' },
    4: { icon: faSmileWink, message: 'strong' },
  };
  return (
    <div className="password__message">
      Strength:
      <span className="password__message-text">{options[score].message}</span>
      <FontAwesomeIcon className={`password__strength_${score}`} icon={options[score].icon} />
    </div>
  );
};

export default strength;
