import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnalytics, faQuestionCircle, faSearch } from '@fortawesome/pro-solid-svg-icons';

import HomeButton from './home-button/home-button';

import './home.css';

const Home = ({
  touchDevice,
}) => (
  <div className="home">
    <section className="home__intro">
      <h1>HUMAN CELL MAP</h1>
      <h2>
        A BioID proximity map of the HEK293 proteome
      </h2>
    </section>
    <section className="home__inner">
      <div className="home__buttons">
        <HomeButton route={touchDevice ? '/explore/search' : '/explore'}>
          <FontAwesomeIcon
            icon={faSearch}
          />
          EXPLORE
        </HomeButton>
        {
          !touchDevice
          && (
            <HomeButton route="/analyze">
              <FontAwesomeIcon
                icon={faAnalytics}
              />
              UPLOAD & ANALYZE
            </HomeButton>
          )
        }
      </div>
      <div className="home__description">
        <p>
          Browse or search the cell map by clicking &quot;EXPLORE&quot;. Alternatively, compare
          and analyze your own data by clicking &quot;UPLOAD & ANALYZE&quot;.
        </p>
        <p>
          Anytime you need help, click the
          <FontAwesomeIcon
            className="home__help-icon"
            icon={faQuestionCircle}
          />
          icon
          in the navigation bar at the top.
        </p>
      </div>
    </section>
    <footer className="home__copyright">
      <small>&#169; 2020, the Gingras lab.</small>
    </footer>
  </div>
);

Home.propTypes = {
  touchDevice: PropTypes.bool.isRequired,
};

export default Home;
