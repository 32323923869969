import React, { Fragment, Suspense } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import Loading from './routes/loading';
import Navbar from './navbar/navbar-container';
import Routing from './routes/routing';
import theme from './style/theme';
import store from './state/store';

import './App.css';

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Fragment>
        <main className="app">
          <Suspense fallback={<Loading />}>
            <Routing>
              <Navbar />
            </Routing>
          </Suspense>
        </main>
      </Fragment>
    </ThemeProvider>
  </Provider>
);

export default App;
