import React from 'react';

import Link from '../components/link/link';

import './not-found.css';

const NotFoundPage = () => (
  <div className="not-found">
    <h1>404: page not found</h1>
    <p>
      The page you were looking for was moved, removed, renamed or never existed.
      You can e-mail us at
      {' '}
      <Link
        to="mailto:contact@cell-map.org"
        visited={false}
      >
        contact@cell-map.org
      </Link>
      {' '}
      if you think there is a problem.
    </p>
    <Link
      className="not-found__link"
      nav
      to="/"
    >
      Home
    </Link>
  </div>
);

export default NotFoundPage;
