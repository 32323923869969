import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-duotone-svg-icons';

import ButtonLink from './button/link';
import TextLink from './text/link';

const formatClass = classes => (classes.length > 0 ? classes.join(' ') : undefined);

const externalLinkRe = new RegExp(/^[^/]/);

const CustomLink = ({
  children,
  className,
  external,
  isButton,
  nav,
  outline,
  removeStyling,
  to,
  visited,
  ...props
}) => {
  const StyledLink = isButton ? ButtonLink : TextLink;
  const TagType = externalLinkRe.test(to) || (to.startsWith('/') && /\.[0-9a-z]+$/i.test(to)) ? 'a' : Link;

  const classes = [];
  if (removeStyling) {
    classes.push('link_no-style');
  }

  if (className) {
    classes.push(className);
  }

  if (nav) {
    classes.push('link_nav');
  } else if (visited) {
    classes.push('link_visited');
  }

  if (external) {
    classes.push('link_external');
  }

  if (outline) {
    classes.push('link_outline');
  }

  return (
    <StyledLink
      as={TagType}
      className={formatClass(classes)}
      href={to}
      rel="noreferrer"
      to={to}
      {...props}
    >
      {children}
      { external && <FontAwesomeIcon icon={faExternalLink} />}
    </StyledLink>
  );
};

CustomLink.defaultProps = {
  className: '',
  external: false,
  isButton: false,
  nav: false,
  outline: false,
  removeStyling: false,
  visited: true,
};

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  external: PropTypes.bool,
  isButton: PropTypes.bool,
  nav: PropTypes.bool,
  outline: PropTypes.bool,
  removeStyling: PropTypes.bool,
  to: PropTypes.string.isRequired,
  visited: PropTypes.bool,
};

export default CustomLink;
