import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Button = styled.button`
  transform-origin: center;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: ${props => props.theme.timingFunction};

  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: 0;
  }

  ${props => (props.disabled
    && css`& {
      cursor: not-allowed;
      opacity: 0.6;
    }`
  )};

  ${props => (props.kind === 'default'
    && css`
      & {
        background-color: rgba(0, 0, 0, 0.2);
        border-color: transparent;
        color: ${props.theme.fontDark};
      }
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: transparent;
        border-color: rgba(0, 0, 0, 0.2);
      }
    `
  )};

  ${props => (props.kind === 'primary'
    && css`
      & {
        background-color: ${props.theme.colorPrimary1};
        border-color: transparent;
        color: ${props.theme.fontLight};
      }
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: transparent;
        border-color: ${props.theme.colorPrimary1};
        color: ${props.theme.fontDark};
      }
    `
  )};

  ${props => (props.kind === 'success'
    && css`
      & {
        background-color: ${props.theme.success};
        border-color: transparent;
        color: #421C1A;
      }
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: transparent;
        border-color: ${props.theme.success};
      }
    `
  )};

  ${props => (props.kind === 'transparent'
    && css`
      & {
        background-color: transparent;
        border-color: ${props.theme.colorPrimary1};
        color: ${props.theme.fontDark};
      }
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: ${props.theme.colorPrimary1};
        border-color: transparent;
        color: ${props.theme.fontLight};
      }
    `
  )};

  ${props => (props.kind === 'warning'
    && css`
      & {
        background-color: ${props.theme.warning};
        border-color: transparent;
        color: #420802;
      }
      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        background-color: transparent;
        border-color: ${props.theme.warning};
      }
    `
  )};
`;

Button.defaultProps = {
  disabled: false,
  kind: 'primary',
};

Button.propTypes = {
  disabled: PropTypes.bool,
  kind: PropTypes.string,
};

export default Button;
