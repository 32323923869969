import styled from 'styled-components';

const Span = styled.span`
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  
  & span {
    align-items: center;
    color: ${props => props.theme.fontDark};
    display: inline-flex;
  }

  & input {
    background-color: ${props => props.theme.backgroundLight};
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    font-size: 0.9em;
    height: 32px;
    padding: 0 10px;
    transition-duration: 0.3s;
    transition-property: box-shadow;
    transition-timing-function: ${props => props.theme.timingFunction || 'ease'}
  }
  &.input__border_very-round input {
    border-radius: 20px;
  }
  &.input__border_round input {
    border-radius: 3px;
  }

  & input:focus {
    box-shadow: ${props => `0px 0px 0px 1px ${props.theme.accentPrimary}`};
    border-color: ${props => props.theme.accentPrimary};
    outline: none;
  }

  & label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 0.7rem;
    margin-left: 0.7rem;
  }

  &.input__icon input {
    margin-left: -25px;
    padding-left: 30px;
    z-index: 1;
  }

  &.input__icon span {
    z-index: 2;
  }

  &.input__label_inline {
    align-items: center;
    flex-direction: row;
    width: auto;
  }
  &.input__label_inline label {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0.5rem;
  }
  &.input__label_inline label::after {
    content: ':';
  }

  .input__warning {
    color: ${props => props.theme.warning};
    margin-left: 10px;
    margin-top: 3px;
  }

  .password__meter {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .password__message {
    margin-left: 10px;
    margin-top: 2px;
  }
  .password__message-text {
    margin: 0 5px;
  }
  meter {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    background-color: rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    height: 10px;
    margin: 5px 10px 0 10px;
    width: 100%;
  }
  meter::-webkit-meter-bar {
    background: none;
  }
  meter[value="0"] {
    background-color: #f44336;
  }
  meter[value="1"]::-webkit-meter-optimum-value {
    background: #f44336;
  }
  meter[value="2"]::-webkit-meter-optimum-value {
    background: #ff9800;
  }
  meter[value="3"]::-webkit-meter-optimum-value {
    background: #fbc02d;
  }
  meter[value="4"]::-webkit-meter-optimum-value {
    background: green;
  }
  meter[value="1"]::-moz-meter-bar {
    background: #f44336;
  }
  meter[value="2"]::-moz-meter-bar {
    background: #ff9800;
  }
  meter[value="3"]::-moz-meter-bar {
    background: #fbc02d;
  }
  meter[value="4"]::-moz-meter-bar {
    background: green;
  }
  .password__strength_0 {
    color: #f44336;
  }
  .password__strength_1 {
    color: #f44336;
  }
  .password__strength_2 {
    color: #ff9800;
  }
  .password__strength_3 {
    color: #fbc02d;
  }
  .password__strength_4 {
    color: green;
  }
`;

export default Span;
