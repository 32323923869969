import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';

import Link from './user-style';

const User = ({
  signedIn,
}) => (
  signedIn
  && (
    <Link
      aria-label="user home page"
      href="/user"
    >
      <FontAwesomeIcon
        className="nav__user"
        icon={faUserCircle}
        size="lg"
      />
    </Link>
  )
);

User.propTypes = {
  signedIn: PropTypes.bool.isRequired,
};

export default User;
