import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

import Button from './help-style';
import Modal from '../../components/modal/modal-container';

const Help = ({
  handleClick,
  handleClose,
  isModalOpen,
  route,
}) => (
  <Fragment>
    <Button
      aria-label="page specific help"
      onClick={handleClick}
      type="button"
    >
      <FontAwesomeIcon
        className="nav__help"
        icon={faQuestionCircle}
        size="lg"
      />
    </Button>
    <Modal
      isOpen={isModalOpen}
      handleClose={handleClose}
      name="help"
    >
      {route}
    </Modal>
  </Fragment>
);

Help.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  route: PropTypes.string.isRequired,
};

export default Help;
