import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Input from './input';

let zxcvbn;
import('zxcvbn').then((result) => {
  zxcvbn = result.default;
});

const InputContainer = ({
  onChange,
  onEnter,
  onFocus,
  showMeter,
  type,
  warning,
  ...props
}) => {
  const [inputWarning, setWarning] = useState(warning);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [displayMeter, setDisplayMeter] = useState(false);

  useEffect(() => {
    setWarning(warning);
  }, [warning]);

  const handleChange = (e) => {
    if (type === 'password' && showMeter) {
      setDisplayMeter(Boolean(e.target.value));
      const { score } = zxcvbn(e.target.value);
      setPasswordStrength(score);
    }
    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e) => {
    setWarning('');
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleKeyPress = (e) => {
    if (onEnter && e.key === 'Enter') {
      onEnter(e.target.value, e);
    }
  };

  return (
    <Input
      {...props}
      displayMeter={displayMeter}
      onKeyPress={handleKeyPress}
      onChange={handleChange}
      onFocus={handleFocus}
      passwordStrength={passwordStrength}
      type={type}
      warning={inputWarning}
    />
  );
};

InputContainer.defaultProps = {
  onChange: undefined,
  onEnter: undefined,
  onFocus: undefined,
  showMeter: false,
  type: 'text',
  warning: '',
};

InputContainer.propTypes = {
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  onFocus: PropTypes.func,
  showMeter: PropTypes.bool,
  type: PropTypes.string,
  warning: PropTypes.string,
};

export default InputContainer;
