const parseURI = (uri) => {
  const path = uri.substr(1);
  if (!path) {
    return {
      help: 'home',
    };
  }
  if (path.startsWith('about')) {
    return {
      route: 'about',
    };
  } if (path.startsWith('analyze')) {
    return {
      help: 'analyze',
      route: 'analyze',
    };
  } if (path.startsWith('downloads')) {
    return {
      route: 'downloads',
    };
  } if (path.startsWith('explore')) {
    return {
      help: path.split('/').slice(0, 2).join('-'),
      route: 'explore',
    };
  } if (path.startsWith('help')) {
    return {
      route: 'help',
    };
  }
  return {};
};

export default parseURI;
