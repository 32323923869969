import styled from 'styled-components';

import DefaultButton from '../button-defaults';

const Button = styled(DefaultButton)`
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  font-size: inherit;
  padding: 0.2em 0.5em;
  transform-origin: center;
  transition-duration: 0.4s;
  transition-property: all;
  transition-timing-function: ${props => props.theme.timingFunction};

  &:active {
    transform: scale(0.9);
  }
`;

export default Button;
