/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import Help from './help/help-container';
import Input from '../components/input/text-round/input-container';
import Link from '../components/link/link';
import Menu from './menu/menu-container';
import Nav from './navbar-style';
import TextLinks from './text-links/text-links';
import User from './user/user-container';

const getLinks = (smallScreen, signedIn) => {
  const links = [];
  if (smallScreen) {
    links.push(...['explore'/* , 'downloads', 'about', 'help' */]);
  } else {
    links.push(...['explore', 'analyze'/* 'downloads', 'about', 'help' */]);
  }

  if (signedIn) {
    links.push('user');
  }
  return links;
};

const Navbar = ({
  handleInputChange,
  handleInputEnter,
  searchTerm,
  signedIn,
  smallScreen,
  uri,
}) => {
  const linksToDisplay = getLinks(smallScreen, signedIn);
  return (
    <Nav>
      <Link
        className="nav__icon"
        nav
        to="/"
      >
        <svg
          aria-label="Cell map icon"
          height={30}
          role="img"
          viewBox="0 0 460.487 473.997"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Cell map</title>
          <desc>icon</desc>
          <path className="nav__icon-background" fill="#F2F2F2" d="M460.487 243.738c0 127.138-103.029 230.259-230.229 230.259C103.09 473.997 0 370.876 0 243.738 0 116.6 103.09 13.51 230.259 13.51c127.199 0 230.228 103.09 230.228 230.228" />
          <path fill="#1F8478" d="M302.254 216.709s94.947-62.993 127.188-102.013c39.832-48.311 23.501-72.938-31.326-99.056-54.854-26.144-84.243-23.344-96.648 38.026-10.075 49.646.786 163.043.786 163.043" />
          <path fill="#DEE6ED" d="M404.999 83.317c-8.4 17.639-29.494 25.124-47.107 16.723-17.639-8.427-25.097-29.494-16.696-47.16 8.375-17.586 29.494-25.071 47.106-16.67 17.613 8.428 25.098 29.494 16.697 47.107" />
          <path fill="#2A4E6E" d="M367.987 280.296c-36.378 0-36.438 56.55 0 56.55 36.347 0 36.409-56.55 0-56.55M352.515 223.044c-27.131 0-27.191 42.084 0 42.084 27.099 0 27.161-42.084 0-42.084" />
          <path fill="#9DB3C8" d="M269.104 108.657c-3.705-5.127-10.497-5.504-17.102-6.201-15.814-1.772-28.593-14.606-34.124-28.97-.859-2.336-1.746-4.751-3.276-6.711-8.108-10.364-26.687-1.047-29.855 9.423-2.979 9.88 6.552 13.102 12.485 18.015 6.739 5.477 4.054 15.142-1.315 20.619-4.027 4.054-9.585 6.417-15.196 7.491-6.954 1.289-14.578 1.664-21.424-.162-8.538-2.309-16.753-8.886-25.854-8.591-7.786.322-13.37 8.404-13.827 15.572-.376 5.557 1.638 11.088 4.699 15.786 1.959 2.953 4.268 4.967 7.947 4.376 8.618-1.369 17.344-3.222 26.069-2.175 10.686 1.316 18.848 13.747 10.632 23.063-3.947 4.483-9.316 4.833-14.739 3.785-2.068.805-4.027 1.745-5.826 3.114-1.423 1.047-2.9 2.711-2.336 4.403.35 1.021 1.343 1.665 2.255 2.229 7.652 4.537 15.546 8.591 23.438 12.672 2.739 1.423 20.377 12.887 22.338 11.276.402-.349.509-.939.617-1.423.564-4.295.188-8.752-.241-12.994-.563-4.725-1.02-9.478.026-14.122 1.88-8.295 11.411-16.028 20.19-12.887 7.92 2.899 11.812 13.048 12.591 20.726.429 4.376.94 6.658 5.853 6.068 3.167-.349 5.96-2.202 8.162-4.457 18.632-19.679-1.584-46.151-25.157-46.231-2.55-.027-5.181.215-7.491-.833-6.926-3.167.35-14.793 1.262-19.733.51-2.846.993-5.746 2.631-8.054 3.167-4.403 9.235-3.114 13.585-1.45 7.866 2.927 13.343 7.115 13.853 16.189.053.94.027 1.88.242 2.792 1.396 6.309 10.121 11.303 16.081 9.612 3.384-.94 6.283-3.034 9.102-5.102 6.148-4.43 7.625-15.679 5.746-22.605-.43-1.825-1.182-3.274-2.041-4.51" />
          <path fill="#4A6B8A" d="M301.885 306.312c6.631 11.383 9.666 21.746 8.994 30.74-.591 15.25-11.329 31.01-30.258 44.567-31.009 22.149-85.402 37.641-129.46 24.404-20.699-6.362-36.164-18.203-46.071-35.144-15.679-26.982-17.827-68.354-6.148-119.688 8.833-36.755 16.915-65.428 32.459-71.549 9.584-3.812 23.625.537 42.848 13.209 36.111 25.158 103.527 72.087 127.636 113.461" />
          <path fill="#133453" d="M208.401 347.388c-5.826 25.587-31.278 41.642-56.891 35.843-25.585-5.826-41.641-31.277-35.815-56.891 5.799-25.586 31.251-41.642 56.891-35.843 25.64 5.827 41.642 31.252 35.815 56.891" />
        </svg>
        <div className="nav__title">
          cell map
        </div>
      </Link>
      <div className="nav__right">
        <Input
          icon={faSearch}
          onChange={handleInputChange}
          onEnter={handleInputEnter}
          placeholder="Gene symbol..."
          value={searchTerm}
        />
        {
        smallScreen
          ? <Menu links={linksToDisplay} signedIn={signedIn} />
          : <TextLinks links={linksToDisplay} route={uri.route} signedIn={signedIn} />
      }
        {
        !smallScreen
        && uri.help
        && <Help route={uri.help} />
      }
        <User />
      </div>
    </Nav>
  );
};

Navbar.defaultProps = {
  smallScreen: false,
};

Navbar.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleInputEnter: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  signedIn: PropTypes.bool.isRequired,
  smallScreen: PropTypes.bool,
  uri: PropTypes.shape({
    help: PropTypes.string,
    route: PropTypes.string,
  }).isRequired,
};

export default Navbar;
