import React from 'react';
import { useSelector } from 'react-redux';

import User from './user';
import { stateSelector } from '../../state/selector/general';

const UserContainer = () => {
  const user = useSelector(state => stateSelector(state, 'user'));

  return (
    <User signedIn={user.signedIn} />
  );
};

export default UserContainer;
