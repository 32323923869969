import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import Div from './menu-style';
import Link from '../../components/link/link';

const Menu = forwardRef((
  {
    links,
    open,
    toggleMenu,
  },
  ref,
) => (
  <Div ref={ref}>
    <button
      aria-label="navigation menu"
      onClick={toggleMenu}
      type="button"
    >
      <FontAwesomeIcon
        className="nav__menu"
        icon={faBars}
        size="lg"
      />
    </button>
    <ul className={open ? 'nav__menu_open' : 'nav__menu_closed'}>
      { links.map(link => <li key={link}><Link nav to={`/${link}`}>{link}</Link></li>) }
    </ul>
  </Div>
));

Menu.defaultProps = {
  links: [],
  open: false,
};

Menu.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
};

export default Menu;
