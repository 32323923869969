import React from 'react';

import StyledDots from './dots-style';

const LoadingDots = () => (
  <StyledDots
    aria-label="loading dots"
    className="loading-dots"
  >
    <span />
    <span />
    <span />
  </StyledDots>
);

export default LoadingDots;
