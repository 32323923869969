import { updateConsent } from '../cookies/cookie-reducer';

// Actions to subscribe to.
export const saveActions = [
  updateConsent.type,
];

export const saveActionKey = {
  [updateConsent.type]: 'cookies',
};
