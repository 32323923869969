import styled from 'styled-components';

const UL = styled.ul`
  display: inline-flex;
  font-family: ${props => props.theme.fontStackPrimary};
  font-size: 1.2rem;
  font-weight: 700;
  height: 50px;
  list-style: none;
  margin: 0;
  padding: 0;

  & li {
    align-items: center;
    display: flex;
    padding: 0 0.4rem;
  }
  & a,
  & a:link,
  & a:active,
  & a:focus,
  & a:hover {
    color: ${props => props.theme.fontLight};
    text-decoration: none;
  }
  & a:after {
    background: ${props => props.theme.fontLight};
    content: '';
    display: block;
    height: 2px;
    transition-duration: 0.3s;
    transition-property: width;
    transition-timing-function: ${props => props.theme.timingFunction};
    width: 0px;
  }
  & a:focus {
    outline: none;
  }
  & a:focus:after,
  & a:hover:after,
  & a:active:after {
    width: 100%;
  }

  .nav__link_active {
    background-color: ${props => props.theme.accentPrimary}; 
  }
  .nav__link_active a {
    color: ${props => props.theme.fontDark}; 
  }
  .nav__link_active a:after {
    background: ${props => props.theme.fontDark};
  }
`;

export default UL;
