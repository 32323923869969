import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import search from '../../state/search/search-thunk';
import { stateSelector } from '../../state/selector/general';

const useSearch = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const searchState = useSelector(state => stateSelector(state, 'search'));

  const performSearch = (searchOptions = {}) => {
    const fdr = Number(searchOptions.fdr || searchState.fdr);
    const term = searchOptions.term || searchState.term;
    if (term) {
      const params = { fdr, term };
      dispatch(search(params));

      if (pathname.startsWith('/explore/search')) {
        history.replace(`/explore/search?${new URLSearchParams(params).toString()}`);
      } else {
        history.push(`/explore/search?${new URLSearchParams(params).toString()}`);
      }
    }
  };

  return performSearch;
};

export default useSearch;
