import React from 'react';

import Home from './home';

import isTouchDevice from '../utils/is-touch-device';

const HomeContainer = () => {
  const touchDevice = isTouchDevice();

  return <Home touchDevice={touchDevice} />;
};

export default HomeContainer;
