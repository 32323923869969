import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { stateSelectorProp } from '../state/selector/general';

const checkSubdomain = host => host.startsWith('private');

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const isAuthenticated = useSelector(state => stateSelectorProp(state, 'user', 'signedIn'));

  const { host } = window.location;
  const requriesAuthentication = checkSubdomain(host);

  return (
    <Route
      {...rest}
      render={props => (
        !requriesAuthentication
      || (
        requriesAuthentication && isAuthenticated
      ) ? (
        <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/user/signin',
                state: {
                  from: `${pathname}${search}`,
                },
              }}
            />
          )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
