import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  abundanceCap: 50,
  activeTask: null,
  fileType: 'saint',
  errors: {},
  preyIdentifier: 'symbol',
  scoreThreshold: 0.01,
  scoreType: 'lte',
  tasks: [],
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    addTask: (state, action) => {
      state.activeTask = action.payload.id;
      state.tasks.push(action.payload);
    },
    setActiveTask: (state, action) => {
      state.activeTask = action.payload;
    },
    setFile: (state, action) => ({
      ...initialState,
      ...action.payload,
      activeTask: state.activeTask,
      tasks: state.tasks,
      errors: {},
    }),
    setFormField: (state, action) => {
      const { id, value } = action.payload;
      state[id] = value;
    },
    setFormFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});


export const {
  addTask,
  setActiveTask,
  setFile,
  setFormField,
  setFormFields,
} = analysisSlice.actions;
export default analysisSlice.reducer;
