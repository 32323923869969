import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import reducers from './reducers';
import reHydrate from './local-storage/rehydrate';
import subscribeMiddleware from './local-storage/subscribe-middleware';

const store = configureStore({
  middleware: [
    subscribeMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['analysis/setFile', 'analysis/setFormField', 'analysis/setFormFields'],
        ignoredPaths: ['analysis.file'],
      },
    }),
  ],
  preloadedState: reHydrate(),
  reducer: reducers,
});

export default store;
