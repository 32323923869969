const theme = {
  // Accenting light colors, e.g. backgroundLight
  accentLight: '#2A4E6E',
  // Accenting dark colors, e.g. colorPrimary1
  accentPrimary: '#64CEF0',
  backgroundLight: '#F7F7F7',
  colorPrimary1: '#2A4E6E',
  colorPrimary2: '#041F37', // dark
  colorPrimary3: '#C2D0DD', // light
  colorPrimary4: '#dee6ed', // lightest
  defaultBorderColor: '#b3b3b3',
  fontDark: '#333',
  fontLight: '#F2F2F2',
  fontStackPrimary: 'Lato, sans-serif',
  fontStackSecondary: 'Roboto, sans-serif',
  success: '#43a047',
  timingFunction: 'cubic-bezier(.17, .67, .16, .99)',
  warning: '#f44336',
};

export default theme;
