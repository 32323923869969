import styled from 'styled-components';

const A = styled.a`
  color: #2468e5;
  text-decoration: none;
  white-space: nowrap;

  &:link {
    color: #2468e5;
  }

  &:link_nav:visited, 
  &:visited {
    color: '#2468e5';

  }
  &.link_visited:visited {
    color: '#1E6C60';
  }

  &.link_external {
    align-items: center;
    display: inline-flex;
  }
  &.link_external .fa-external-link {
    font-size: 0.8rem;
    margin-left: 0.3rem;
  }

  &:hover,
  &:active {
    color: #23527c;
    text-decoration: underline;
  }

  &:focus {
    color: #23527c;
    outline: none;
    text-decoration: underline;
  }

  &.link_outline:focus {
    outline: auto;
    outline-color: #4D90FE;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 2px;
  }

  &.link_no-style,
  &.link_no-style:link,
  &.link_no-style:visited,
  &.link_no-style:hover,
  &.link_no-style:active,
  &.link_no-style:focus {
    color: inherit;
    text-decoration: none;
  }
`;

export default A;
