import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  consent: undefined,
};

const cookieSlice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    updateConsent: (state, action) => {
      state.consent = action.payload;
    },
  },
});


export const { updateConsent } = cookieSlice.actions;
export default cookieSlice.reducer;
