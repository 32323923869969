import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none;
  }

  & .nav__help {
    cursor: pointer;
    height: 20px;
    width: 25px;
  }
  & .nav__help > * {
    transition: fill 0.3s ease;
    fill: ${props => props.theme.fontLight};
  }
  &:focus *,
  &:hover *,
  &:active * {
    fill: ${props => props.theme.accentPrimary};
  }
`;

export default Button;
