import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Navbar from './navbar';
import parseURI from './parse-uri';
import useSearch from '../hooks/search/use-search';
import useSmallScreen from '../hooks/small-screen/use-small-screen';
import { setSearchTerm } from '../state/search/search-reducer';
import { stateSelectorProp } from '../state/selector/general';

const NavbarContainer = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const search = useSearch();
  const searchTerm = useSelector(state => stateSelectorProp(state, 'search', 'term'));
  const signedIn = useSelector(state => stateSelectorProp(state, 'user', 'signedIn'));
  const smallScreen = useSmallScreen(0, 880);

  const handleInputChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  const handleInputEnter = () => {
    search();
  };

  const uri = parseURI(pathname);

  return (
    <Navbar
      handleInputChange={handleInputChange}
      handleInputEnter={handleInputEnter}
      searchTerm={searchTerm}
      signedIn={signedIn}
      smallScreen={smallScreen}
      uri={uri}
    />
  );
};

export default NavbarContainer;
