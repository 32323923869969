import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  name: '',
  signedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => initialState,
    setUser: (state, action) => {
      const { email, name } = action.payload;
      state.email = email;
      state.name = name;
      state.signedIn = true;
    },
    validationFailure: () => initialState,
  },
});

export const { clearUser, setUser, validationFailure } = userSlice.actions;
export default userSlice.reducer;
