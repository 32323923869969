import React from 'react';

import Funding from './about__funding';
import Link from '../components/link/link';

import './about.css';

const About = () => (
  <div className="about">
    <div className="about__inner">
      <p>
        The Cell Map is an ongoing project of the
        {' '}
        <Link to="http://gingraslab.lunenfeld.ca">
          Gingras laboratory
        </Link>
        {' '}
        at the Lunenfeld-Tanenbaum Research Institute in Toronto. The project aims
        to map the cell using proximity biotinylation coupled with mass spectrometry.
        The initial map is for HEK 293 cells but will be expanded to incorporate
        other cell lines in the future. For questions or comments, please email
        {' '}
        <Link
          to="mailto:contact@cell-map.org"
          visited={false}
        >
          contact@cell-map.org
        </Link>
        .
      </p>
      <p>
        When using information or data from the Cell Map, please cite:
        Go et al. (in preparation), &quot;A proximity biotinylation map of a human cell&quot;.
      </p>
      <Funding />
    </div>
  </div>
);

export default About;
