import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import Help from './help';

const HelpContainer = ({
  route,
}) => {
  const [isModalOpen, setState] = useState(false);

  const handleClick = () => {
    setState(!isModalOpen);
  };

  const handleClose = useCallback(() => {
    setState(false);
  }, [setState]);

  return (
    <Help
      handleClick={handleClick}
      handleClose={handleClose}
      isModalOpen={isModalOpen}
      route={route}
    />
  );
};

HelpContainer.propTypes = {
  route: PropTypes.string.isRequired,
};

export default HelpContainer;
