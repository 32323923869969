import { createSlice } from '@reduxjs/toolkit';

import fetchReport from './reports-thunk';

const initialState = {
  activeReport: {
    id: null,
    reportType: null,
  },
  bait: {},
  organelle: {},
  prey: {},
};

const defaultSortState = {
  bait: {
    go: {
      column: 'pvalue',
      direction: 'asc',
    },
    preys: {
      column: 'lasc',
      direction: 'des',
    },
  },
  prey: {
    baits: {
      column: 'normalized',
      direction: 'des',
    },
  },
};

const defaultActiveTab = {
  bait: 'prey',
  prey: 'bait',
};

const defaultFilters = {
  bait: {
    avgspec: 0,
    fdr: 0,
    foldchange: 0,
    lasc: 0,
    normalized: 0,
    specificity: 0,
  },
  prey: {
    avgspec: 0,
    fdr: 0,
    foldchange: 0,
    lasc: 0,
    normalized: 0,
    specificity: 0,
  },
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    deleteReport: (state, action) => {
      const { id, reportType } = action.payload;
      delete state[reportType][id];
    },
    setActiveReport: (state, action) => {
      state.activeReport = action.payload;
    },
    setActiveReportTab: (state, action) => {
      const { id, reportType, tab } = action.payload;
      state[reportType][id].activeTab = tab;
    },
    setFilter: (state, action) => {
      const {
        id,
        name,
        reportType,
        value,
      } = action.payload;
      state[reportType][id].filters[name] = value;
    },
    setSortState: (state, action) => {
      const {
        column,
        direction,
        id,
        reportType,
        table,
      } = action.payload;
      state[reportType][id].sortState[[table]] = {
        column,
        direction,
      };
    },
    toggleFilters: (state, action) => {
      const { id, reportType } = action.payload;
      state[reportType][id].showFilters = !state[reportType][id].showFilters;
    },
  },
  extraReducers: {
    [fetchReport.fulfilled]: (state, action) => {
      const { fdr, id, reportType } = action.meta.arg;
      state[reportType][id] = {
        ...action.payload,
        error: false,
        filters: {
          ...defaultFilters[reportType],
          fdr: Number(fdr),
        },
        pending: false,
        activeTab: defaultActiveTab[reportType],
        showFilters: false,
        sortState: defaultSortState[reportType],
      };
    },
    [fetchReport.pending]: (state, action) => {
      const { id, openInBackground, reportType } = action.meta.arg;
      if (!openInBackground) {
        state.activeReport = { id, reportType };
      }
      state[reportType][id] = {
        pending: true,
      };
    },
    [fetchReport.rejected]: (state, action) => {
      const { id, reportType } = action.meta.arg;
      state[reportType][id] = {
        error: true,
        pending: false,
      };
    },
  },
});


export const {
  deleteReport,
  setActiveReport,
  setActiveReportTab,
  setFilter,
  setSortState,
  toggleFilters,
} = reportsSlice.actions;
export default reportsSlice.reducer;
