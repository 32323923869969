import { createSlice } from '@reduxjs/toolkit';

import fetchByTerm from './search-thunk';

const initialState = {
  activeTab: 'preys',
  baits: [],
  didSearch: false,
  error: false,
  fdr: 0.01,
  pending: false,
  preys: [],
  sortColumn: {
    baits: '',
    preys: '',
  },
  sortDirection: {
    baits: '',
    preys: '',
  },
  term: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchFDR: (state, action) => {
      state.fdr = action.payload;
    },
    setSearchFields: (state, action) => {
      state.fdr = action.payload.fdr;
      state.term = action.payload.term;
    },
    setSearchTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.error = false;
      state.message = '';
      state.term = action.payload;
    },
    setSortState: (state, action) => {
      state.sortColumn[action.payload.table] = action.payload.column;
      state.sortDirection[action.payload.table] = action.payload.direction;
    },
  },
  extraReducers: {
    [fetchByTerm.fulfilled]: (state, action) => {
      const { baits, preys } = action.payload;
      state.baits = baits;
      state.didSearch = true;
      state.error = false;
      state.message = '';
      state.pending = false;
      state.preys = preys;
    },
    [fetchByTerm.pending]: (state) => {
      state.baits = [];
      state.error = false;
      state.message = '';
      state.pending = true;
      state.preys = [];
    },
    [fetchByTerm.rejected]: (state) => {
      state.baits = [];
      state.error = true;
      state.pending = false;
      state.preys = [];
    },
  },
});


export const {
  setSearchFDR,
  setSearchFields,
  setSearchTab,
  setSearchTerm,
  setSortState,
} = searchSlice.actions;
export default searchSlice.reducer;
