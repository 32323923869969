import PropTypes from 'prop-types';
import React from 'react';

import Link from '../../components/link/link';

import './home-button.css';

const HomeButton = ({
  children,
  route,
}) => (
  <Link
    className="home__button"
    nav
    to={route}
  >
    {children}
  </Link>
);

HomeButton.propTypes = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string.isRequired,
};

export default HomeButton;
