import { createAsyncThunk } from '@reduxjs/toolkit';

import fetch from '../../utils/fetch';

const fetchByTerm = createAsyncThunk(
  'search/fetchByTermStatus',
  async (options, thunkAPI) => {
    const { fdr, term } = options;
    const route = `/search?fdr=${fdr}&term=${term}`;
    const response = await fetch(route);

    if (response.error) {
      return thunkAPI.rejectWithValue('search failed');
    }
    return response.data;
  },
);

export default fetchByTerm;
