import styled from 'styled-components';

const Nav = styled.nav`
  align-items: center;
  background-color: ${props => props.theme.colorPrimary1};
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: ${props => props.theme.fontLight};
  display: flex;
  font-family: ${props => props.theme.fontStackSecondary};
  height: 50px;
  padding: 0 1rem 0 0.5rem;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  width: 100%;
  z-index: 5;

  .nav__icon {
    align-items: center;
    color: ${props => props.theme.fontLight};
    display: flex;
    text-decoration: none;
  }
  .nav__icon-background {
    fill: '#000';
    transition: fill 0.3s ease;
  }
  .nav__icon:focus .nav__icon-background,
  .nav__icon:hover .nav__icon-background {
    fill: ${props => props.theme.accentPrimary};
  }
  .nav__icon:focus .nav__title,
  .nav__icon:hover .nav__title {
    color: ${props => props.theme.accentPrimary};
  }

  .nav__right {
    align-items: center;
    display: flex;
    font-family: ${props => props.theme.fontStackPrimary};
    margin-left: auto;
  }
  .nav__right input {
    margin-right: 1rem;
    width: 160px;
  }

  .nav__right .nav__help,
  .nav__right .nav__user {
    margin: 0 0.5rem;
  }

  .nav__title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-left: 1rem;
    transition: color 0.3s ease;
  }

  @media (max-width: 399px) {
    .nav__title {
      display: none;
    }
  }
`;

export default Nav;
