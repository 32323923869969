import styled from 'styled-components';

const A = styled.a`
  text-decoration: none;

  &:focus {
    outline: none;
  }

  & .nav__user {
    cursor: pointer;
    height: 20px;
    width: 25px;
  }
  & .nav__user > * {
    transition: fill 0.3s ease;
    fill: ${props => props.theme.fontLight};
  }
  &:focus *,
  &:hover *,
  &:active * {
    fill: ${props => props.theme.accentPrimary};
  }
`;

export default A;
