import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Span from './input-style';
import strength from './strength';

const Input = ({
  borderRadius,
  className,
  displayMeter,
  icon,
  id,
  label,
  labelPlacement,
  passwordStrength,
  type,
  warning,
  ...props
}) => {
  const hasIcon = Object.keys(icon).length > 0;
  const classes = ['input'];
  if (className) {
    classes.push(className);
  }
  if (hasIcon) {
    classes.push('input__icon');
  }
  if (labelPlacement === 'inline') {
    classes.push('input__label_inline');
  }
  classes.push(`input__border_${borderRadius}`);

  return (
    <Span className={classes.join(' ')}>
      {
        label
        && (
          <label htmlFor={id}>
            {label}
          </label>
        )
      }
      <span>
        {
          hasIcon
          && (
            <span>
              <FontAwesomeIcon icon={icon} />
            </span>
          )
        }
        <input
          id={id}
          type={type}
          {...props}
        />
      </span>
      {
        type === 'password'
        && displayMeter
        && (
          <span className="password__meter">
            <meter max="4" value={passwordStrength} />
            {strength(passwordStrength)}
          </span>
        )
      }
      {
        warning
        && (
          <span className="input__warning">
            {warning}
          </span>
        )
      }
    </Span>
  );
};

Input.defaultProps = {
  borderRadius: 'very-round',
  className: undefined,
  icon: {},
  id: undefined,
  label: undefined,
  labelPlacement: 'block',
  warning: '',
};

Input.propTypes = {
  borderRadius: PropTypes.string,
  className: PropTypes.string,
  displayMeter: PropTypes.bool.isRequired,
  icon: PropTypes.shape({}),
  id: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  passwordStrength: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  warning: PropTypes.string,
};

export default Input;
